import React, {useEffect, useState} from 'react';
import Counter from '../../components/countdown';
import Button from '../../components/button';

import illustration1 from '../../images/pricing-screenshots/hexomatic-vs-all.png';
import BadgeSection from '../../components/badge-section';
import headerText from '../../images/premium-credits-offer/badge-ltd.svg';

import {
  cleanURL,
  getRawCookie,
  removeDocumentReferrer,
  removeImpactClickIdCookie,
  removeImpactMediaPartnerIdCookie,
  setDocumentReferrer,
  setImpactClickIdCookie,
  setImpactMediaPartnerIdCookie,
} from '../../helpers';
import './styles.scss';
import {Link} from 'gatsby';
import AutomationCards from '../../components/automation/automation-cards';
import {Col, Row} from 'react-bootstrap';
import TrustedSection from '../../components/trusted-section';
import Footer from '../../components/footer';
import {checkedIconPrice} from '../../images/svgIcons';
import {useQuery} from '@apollo/react-hooks';
import {LEFT_PACKAGES_COUNT, LEFT_PREMMIUMS_COUNT} from '../../graphql/queries';

const PremiumsPromo = () => {
  // useEffect(() => {
  //   window.location.href='https://dash.hexomatic.com/dashboard';
  // }, []);

  const [showAll, setShowAll] = useState(false);
  const [leftSeats, setLeftSeats] = useState(0);

  const {data: seatsLeft} = useQuery(LEFT_PREMMIUMS_COUNT);

  useEffect(() => {
    if (seatsLeft?.User?.getPromoLeftSeats?.seats) {
      const count = JSON.parse(seatsLeft.User.getPromoLeftSeats.seats);
      count && count.left_count > 0 && setLeftSeats(count.left_count);
    }
  }, [seatsLeft]);

  const [automations, setAutomations] = useState([
    {
      id: 150,
      name: 'ChatGPT Vision',
      is_new: true,
      containedPublicWorkflows: [],
      prices: [
        {
          id: 155,
          unit_name: 'request',
          credit_price: 1,
          premium_credit_price: 0,
        },
        {
          id: 156,
          unit_name: 'count',
          credit_price: 0,
          premium_credit_price: 0.0036,
        },
      ],
      url: 'chatgpt-vision',
      meta_title: 'Explore ChatGPT (GPT-4 Turbo 128K) automation',
      meta_description:
        "Automate vision tasks via ChatGPT. Tap into Open AI's file processing and image detection model to perform analysis at scale.",
      has_source: false,
      short_description:
        "Automate vision tasks via ChatGPT. Tap into Open AI's file processing and image detection model to perform analysis at scale.",
      long_description:
        'Our ChatGPT Vision Automation revolutionizes image analysis and vision tasks. Ideal for running analysis on files as well as creating product descriptions based on an image.',
      long_description_details:
        '<br />**Different ways to use the ChatGPT Vision automation:**\n<br />\n>* Via the Data input automation (using the Manual paste / List of inputs option) to automate tasks.\n>* Via the Data input automation (using the Upload file / CSV file option) to automate tasks based on your previously collected data.\n>* Via a Hexomatic workflow, performing tasks based on the data scraped or generated by Hexomatic.\n<br />\n\n**How to use the ChatGPT Vision automation via a Data input:**\n<br /> Step 1: Create a blank workflow from your dashboard and choose Data input as the starting point.\nStep 2: Paste the list of inputs (manual paste) or upload the CSV file (upload file) you want to automate.\nStep 3: Search for the ChatGPT Vision automation and add it to your workflow.\nStep 4: Next, choose the source and prompt option: prompt template or custom prompt.\nStep 5: Click continue to run or schedule the workflow.',
      icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/ChatGPT.svg',
      category: {
        id: 21,
        name: 'AI services',
      },
      type: {
        id: 5,
        name: 'Premium automations',
      },
      inputs: 'text, number, email address, any, phone number, boolean, url',
      outputs: '{"_ChatGPT_4_vision":"text","_prompt_ChatGPT_4_vision":"text"}',
      active: true,
      rate: 4.1,
    },
    {
      id: 149,
      name: 'Google Gemini',
      is_new: true,
      containedPublicWorkflows: [],
      prices: [
        {
          id: 151,
          unit_name: 'request',
          credit_price: 1,
          premium_credit_price: 0,
        },
        {
          id: 152,
          unit_name: 'count',
          credit_price: 0,
          premium_credit_price: 0.00012,
        },
      ],
      url: 'google-gemini',
      meta_title: 'Google Gemini Automation | AI Text Generator',
      meta_description:
        "Gemini is Google's latest AI model designed to natively understand and reason with various types of text data.",
      has_source: false,
      short_description:
        "Gemini is Google's latest AI model designed to natively understand and reason with various types of text data.",
      long_description:
        "Gemini is Google's latest AI model designed to natively understand and reason with various types of text data, excelling in complex tasks across multiple domains. Its advanced reasoning capabilities enable the extraction of insights from vast datasets, accelerating breakthroughs in fields ranging from science to finance.",
      long_description_details: '',
      icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/google-gemini-icon.svg',
      category: {
        id: 21,
        name: 'AI services',
      },
      type: {
        id: 5,
        name: 'Premium automations',
      },
      inputs: 'text, url, number, email address, any, phone number, boolean',
      outputs: '{"_prompt_google_gemini":"text","_google_gemini":"text"}',
      active: true,
      rate: 4.1,
    },
    {
      id: 146,
      name: 'ChatGPT (GPT-4 Turbo 128K)',
      is_new: true,
      containedPublicWorkflows: [],
      prices: [
        {
          id: 151,
          unit_name: 'request',
          credit_price: 1,
          premium_credit_price: 0,
        },
        {
          id: 152,
          unit_name: 'count',
          credit_price: 0,
          premium_credit_price: 0.0036,
        },
      ],
      url: 'chatgpt-4',
      meta_title: 'Explore ChatGPT (GPT-4 Turbo 128K) automation - Automate GPT-4',
      meta_description:
        'Leverage the most capable GPT model to date, featuring a massive 128K token context window to perform a wide range of human tasks at scale.',
      has_source: false,
      short_description:
        'Leverage the most capable GPT model to date, featuring a massive 128K token context window to perform a wide range of human tasks at scale.',
      long_description:
        'Leverage the most capable GPT model to date, featuring a massive 128K token context window to perform a wide range of human tasks at scale.\n\nGPT-4 Turbo is more capable and has knowledge of world events up to April 2023. It has a 128k context window so it can fit the equivalent of more than 300 pages of text in a single prompt. \n\nThis model is ideal to perform advanced analysis or writing tasks on the data you scraped or uploaded.',
      long_description_details:
        'Managing large volumes of data can be a challenging and time-consuming endeavor when it comes to data generation. Although ChatGPT can assist in gathering the required information, it lacks the capability to handle prompts on a larger scale.\n<br /> This is where Hexomatic comes into play. Our ChatGPT-4 automation enables you to establish a team of ChatGPT agents that operate on autopilot, streamlining your workflow. With the integration of ChatGPT, you can now carry out tasks such as summarizing, rewriting, enhancing, content creation, analysis, crafting social media posts, and much more in bulk. Additionally, you have the flexibility to apply these tasks to the data you extract through Hexomatic, opening up endless possibilities.\n<br />**Different ways to use the ChatGPT (GPT-4 Turbo) automation:**\n<br />\n>* Via the Data input automation (using the Manual paste / List of inputs option) to automate tasks.\n>* Via the Data input automation (using the Upload file / CSV file option) to automate tasks based on your previously collected data.\n>* Via a Hexomatic workflow, performing tasks based on the data scraped or generated by Hexomatic.\n<br />\n\n**How to use the ChatGPT (GPT-4 Turbo) automation via a Data input:**\n<br /> Step 1: Create a blank workflow from your dashboard and choose Data input as the starting point.\nStep 2: Paste the list of inputs (manual paste) or upload the CSV file (upload file) you want to automate.\nStep 3: Search for the ChatGPT (GPT-4 Turbo) automation and add it to your workflow.\nStep 4: Next, choose the source and prompt option: prompt template or custom prompt.\nStep 5: Click continue to run or schedule the workflow.\n',
      icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/ChatGPT.svg',
      category: {
        id: 21,
        name: 'AI services',
      },
      type: {
        id: 5,
        name: 'Premium automations',
      },
      inputs: 'text, url, number, email address, any, phone number, boolean',
      outputs: '{"_prompt_ChatGPT_4":"text","_ChatGPT_4":"text"}',
      active: true,
      rate: 4.1,
    },
    {
      id: 147,
      name: 'Google Gemini',
      is_new: true,
      containedPublicWorkflows: [],
      prices: [
        {
          id: 149,
          unit_name: 'count',
          credit_price: 0,
          premium_credit_price: 0.00012,
        },
        {
          id: 150,
          unit_name: 'request',
          credit_price: 1,
          premium_credit_price: 0,
        },
      ],
      url: 'google-gemini',
      meta_title: 'Google Gemini Automation | AI Text Generator',
      meta_description:
        'Google Gemini automation is finally here! It can summarize any text, provide answers to FAQs, classify/identify the sentiment of the text, extract a piece of info.',
      has_source: false,
      short_description:
        'Use Gemini, Google’s AI chat assistant, to quickly answer questions and generate content using fresh data from the web.',
      long_description:
        'Need quick answers to frequently asked questions? Google Gemini has got you covered! Its advanced natural language processing capabilities enable it to provide accurate and concise responses to any queries. You can easily automate the creation of the FAQ in seconds document from knowledge base content.',
      long_description_details:
        "How many hours do you spend reading and summarizing lengthy texts? What about extracting a specific piece of information from it? You will no longer have to waste your time when dealing with long texts as our automation will complete all the text-related complex tasks for you!\n<br />Hexomatic's Google Gemini automation allows you to streamline the text generation process, giving you more time for other priorities.\n<br />With our Google Gemini automation, you can summarize any text in a matter of seconds, condensing the key points and saving you valuable time.\n<br />Need quick answers to frequently asked questions? Google Gemini has got you covered! Its advanced natural language processing capabilities enable it to provide accurate and concise responses to any queries. You can easily automate the creation of the FAQ in seconds document from knowledge base content.\n<br />Moreover, extracting specific pieces of information from vast datasets or documents is now possible with Google Gemini. Whether you need to find specific data points, relevant statistics, or crucial facts, our automation tool will swiftly search and present the information you seek.\n<br />**Different ways to use the Google Gemini automation:**\n<br />\n>* Via the Data input automation (using the Manual paste / List of inputs option) to generate texts.\n>* Via the Data input automation (using the Upload file / CSV file option) to generate texts from a local CSV file.\n\n<br />**How to use the Google Gemini automation via a Data input:**\n<br /> Step 1: Create a blank workflow from your dashboard and choose Data input as the starting point.\nStep 2: Paste the list of inputs (manual paste) or upload the CSV file (upload file) you want to automate.\nStep 3: Search for the Google Gemini automation and add it to your workflow.\nStep 4: Next, choose the source and prompt option: prompt template or custom prompt.\nStep 5: Click continue to run or schedule the workflow.",
      icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/google-gemini-icon.svg',
      category: {
        id: 21,
        name: 'AI services',
      },
      type: {
        id: 5,
        name: 'Premium automations',
      },
      inputs: 'text, url, number, email address, any, phone number, boolean',
      outputs: '{"_prompt_google_bard_1":"text","_google_bard_1":"text"}',
      active: true,
      rate: 4.1,
    },
    {
      id: 30,
      name: 'Google Search',
      is_new: false,
      containedPublicWorkflows: [
        {
          public_workflow_id: 29,
          public_workflow_name: 'Find resources page SEO opportunities',
          public_workflow_description:
            'Find curated lists that can link to your own website in minutes. This workflow looks for existing resource pages for a specific keyword on Google and finds contact details on autopilot.',
          public_workflow_created_at: '1631710483422',
          steps: [
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/google-search.png',
              automation_name: 'Google Search',
            },
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/emails-scraper.svg',
              automation_name: 'Emails scraper',
            },
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/social-links-scraper.svg',
              automation_name: 'Social links scraper',
            },
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/phone-number-scraper.svg',
              automation_name: 'Phone number scraper',
            },
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/email-validation.svg',
              automation_name: 'Email address validation',
            },
          ],
          categories: [
            {
              id: 2,
              name: 'Marketing',
            },
          ],
        },
        {
          public_workflow_id: 40,
          public_workflow_name: 'Find domains linking to the top ranking sites for a keyword',
          public_workflow_description:
            'Automate your backlink research at scale by reverse-engineering the referring domains that link to the top ranking websites for any keyword on Google. This workflow creates a comprehensive report containing the best backlink partners and their metrics.',
          public_workflow_created_at: '1634214531350',
          steps: [
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/google-search.png',
              automation_name: 'Google Search',
            },
            {
              automation_icon_url:
                'https://storage.googleapis.com/hexomatic-automation-icons/seo-referring-domains.svg',
              automation_name: 'SEO referring domains',
            },
          ],
          categories: [
            {
              id: 2,
              name: 'Marketing',
            },
            {
              id: 3,
              name: 'Research',
            },
          ],
        },
        {
          public_workflow_id: 42,
          public_workflow_name: 'Find all the pages that link to the top ranking websites',
          public_workflow_description:
            'Automate backlink research at scale by reverse-engineering the pages that link to the top ranking websites for any keyword on Google. This workflow creates a comprehensive report containing the best backlinks and their metrics.',
          public_workflow_created_at: '1634216937457',
          steps: [
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/google-search.png',
              automation_name: 'Google Search',
            },
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/seo-backlink.svg',
              automation_name: 'SEO backlink explorer',
            },
          ],
          categories: [
            {
              id: 2,
              name: 'Marketing',
            },
            {
              id: 3,
              name: 'Research',
            },
          ],
        },
        {
          public_workflow_id: 43,
          public_workflow_name: 'Research backlink partners & contact details via keyword',
          public_workflow_description:
            'Automate backlink research at scale by reverse-engineering the SEO backlink partners that power the top ranking websites for any keyword on Google. This workflow creates a comprehensive report containing the best backlinks and contact details for each publisher.',
          public_workflow_created_at: '1634216945690',
          steps: [
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/google-search.png',
              automation_name: 'Google Search',
            },
            {
              automation_icon_url:
                'https://storage.googleapis.com/hexomatic-automation-icons/seo-referring-domains.svg',
              automation_name: 'SEO referring domains',
            },
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/social-links-scraper.svg',
              automation_name: 'Social links scraper',
            },
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/emails-scraper.svg',
              automation_name: 'Emails scraper',
            },
          ],
          categories: [
            {
              id: 2,
              name: 'Marketing',
            },
            {
              id: 3,
              name: 'Research',
            },
          ],
        },
        {
          public_workflow_id: 53,
          public_workflow_name: 'Audit businesses ranking on Google for accessibility issues',
          public_workflow_description:
            'Run an in-depth WCAG 2.1 accessibility audit on the top ranking websites in Google for any keyword. This workflow is ideal to create branded pdf reports you can share as an ice breaker with prospects.',
          public_workflow_created_at: '1636725119480',
          steps: [
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/google-search.png',
              automation_name: 'Google Search',
            },
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/accessibility-audit.svg',
              automation_name: 'Accessibility audit',
            },
          ],
          categories: [
            {
              id: 2,
              name: 'Marketing',
            },
            {
              id: 3,
              name: 'Research',
            },
          ],
        },
        {
          public_workflow_id: 59,
          public_workflow_name: 'Discover Influencers contact details',
          public_workflow_description:
            'Meta description: Find influencers, bloggers, or publishers to promote your business in minutes.\nThis workflow looks for existing reviews on Google and finds contact details on autopilot.',
          public_workflow_created_at: '1643206232725',
          steps: [
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/google-search.png',
              automation_name: 'Google Search',
            },
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/emails-scraper.svg',
              automation_name: 'Emails scraper',
            },
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/email-discovery.svg',
              automation_name: 'Email discovery',
            },
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/social-links-scraper.svg',
              automation_name: 'Social links scraper',
            },
          ],
          categories: [
            {
              id: 2,
              name: 'Marketing',
            },
          ],
        },
        {
          public_workflow_id: 60,
          public_workflow_name: 'Find affiliates for your product or service',
          public_workflow_description:
            'Find affiliates to promote your affiliate marketing program in minutes.\nThis workflow looks for existing affiliate reviews on Google and finds contact details on autopilot.',
          public_workflow_created_at: '1643206272851',
          steps: [
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/google-search.png',
              automation_name: 'Google Search',
            },
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/emails-scraper.svg',
              automation_name: 'Emails scraper',
            },
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/social-links-scraper.svg',
              automation_name: 'Social links scraper',
            },
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/email-discovery.svg',
              automation_name: 'Email discovery',
            },
          ],
          categories: [
            {
              id: 2,
              name: 'Marketing',
            },
          ],
        },
        {
          public_workflow_id: 61,
          public_workflow_name: 'Find SEO guest post opportunities',
          public_workflow_description:
            'Find guest post opportunities for any keyword or industry including email and social media profile contact details for each publisher.',
          public_workflow_created_at: '1643206295094',
          steps: [
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/google-search.png',
              automation_name: 'Google Search',
            },
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/emails-scraper.svg',
              automation_name: 'Emails scraper',
            },
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/email-validation.svg',
              automation_name: 'Email address validation',
            },
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/social-links-scraper.svg',
              automation_name: 'Social links scraper',
            },
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/phone-number-scraper.svg',
              automation_name: 'Phone number scraper',
            },
          ],
          categories: [
            {
              id: 2,
              name: 'Marketing',
            },
          ],
        },
        {
          public_workflow_id: 78,
          public_workflow_name: 'Scrape Google search to a spreadsheet',
          public_workflow_description:
            'Turn any Google search into a structured spreadsheet. Ideal for research at scale.',
          public_workflow_created_at: '1673948559903',
          steps: [
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/google-search.png',
              automation_name: 'Google Search',
            },
          ],
          categories: [
            {
              id: 1,
              name: 'Sales',
            },
          ],
        },
        {
          public_workflow_id: 84,
          public_workflow_name: 'Market analysis',
          public_workflow_description:
            'Research any topic or product category using the power of web scraping and ChatGPT. Simply specify a product category to search for and run this workflow to get detailed analysis and a product review.',
          public_workflow_created_at: '1684833087701',
          steps: [
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/google-search.png',
              automation_name: 'Google Search',
            },
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/article-curator.svg',
              automation_name: 'Article scraper',
            },
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/ChatGPT.svg',
              automation_name: 'ChatGPT (GPT-3.5)',
            },
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/ChatGPT.svg',
              automation_name: 'ChatGPT (GPT-3.5)',
            },
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/ChatGPT.svg',
              automation_name: 'ChatGPT (GPT-3.5)',
            },
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/ChatGPT.svg',
              automation_name: 'ChatGPT (GPT-3.5)',
            },
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/ChatGPT.svg',
              automation_name: 'ChatGPT (GPT-3.5)',
            },
          ],
          categories: [
            {
              id: 4,
              name: 'ChatGPT',
            },
            {
              id: 3,
              name: 'Research',
            },
          ],
        },
        {
          public_workflow_id: 85,
          public_workflow_name: 'Create ice breakers for Google Search leads',
          public_workflow_description: 'Find leads from Google Maps and create interesting icebreakers on autopilot.',
          public_workflow_created_at: '1684833810303',
          steps: [
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/google-search.png',
              automation_name: 'Google Search',
            },
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/seo.svg',
              automation_name: 'SEO meta tags',
            },
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/article-curator.svg',
              automation_name: 'Article scraper',
            },
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/ChatGPT.svg',
              automation_name: 'ChatGPT (GPT-3.5)',
            },
          ],
          categories: [
            {
              id: 4,
              name: 'ChatGPT',
            },
            {
              id: 1,
              name: 'Sales',
            },
          ],
        },
      ],
      prices: [
        {
          id: 12,
          unit_name: 'request',
          credit_price: 1,
          premium_credit_price: 0.04,
        },
      ],
      url: 'google-search',
      meta_title: 'Scrape Google Search | Hexomatic',
      meta_description:
        'Perform Google searches and get SERP data instantly via our Google search automation. No proxies or scraping recipes are required.',
      has_source: true,
      short_description: 'Perform Google searches and get SERP data',
      long_description:
        'Perform Google searches and get results via our managed automation (0.04 premium credit per query to get 100 search results). No proxies or scraping recipes required.',
      long_description_details:
        "Google Search is a web search engine developed by Google. It's the most widely used search engine on the World Wide Web, handling billions of searches each day.\n<br />Our Google Search automation is an easy way to get SERP data easily and quickly. No proxies or scraping recipes are required.\n<br />**Different ways to use our Google Search automation:**\n<br />\n> * Via Data input automation (using the Manual paste / List of inputs) to check a list of keywords and find SERP data.\n> * Via Data input automation (using the Upload file / CSV file) to check a list of keywords in a local CSV file.\n> * Checking keywords and finding SERP data from your Hexomatic workflow.\n<br />\n\n**How to use our Google Search automation:**\n<br />Step 1: Create a new blank workflow.\nStep 2: Add the Google Search automation. After, add the search keyword, the target country, your device type, the search type, and the number of search results to be displayed.\nStep 3: Click continue to run or schedule the workflow.",
      icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/google-search.png',
      category: {
        id: 1,
        name: 'Scraping',
      },
      type: {
        id: 5,
        name: 'Premium automations',
      },
      inputs: 'any',
      outputs:
        '{"_url":"url","_rank_group":"number","_rank_absolute":"number","_domain":"url","_title":"text","_cache_url":"url","_breadcrumb":"url","_description":"text","_search_type":"text","_keyword":"text"}',
      active: true,
      rate: 4.1,
    },
    {
      id: 31,
      name: 'Google Maps',
      is_new: false,
      containedPublicWorkflows: [
        {
          public_workflow_id: 69,
          public_workflow_name: 'Find B2B leads from Google Maps',
          public_workflow_description:
            'Find local businesses or B2B prospects for any industry and geographic location on autopilot. This workflow enriches every prospect with contact details and social media profiles for easy outreach.',
          public_workflow_created_at: '1645536733167',
          steps: [
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/google-maps.svg',
              automation_name: 'Google Maps',
            },
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/email-discovery.svg',
              automation_name: 'Email discovery',
            },
          ],
          categories: [
            {
              id: 1,
              name: 'Sales',
            },
          ],
        },
        {
          public_workflow_id: 77,
          public_workflow_name: 'Scrape Google Maps to a spreadsheet',
          public_workflow_description:
            'Turn any Google Maps search into a structured spreadsheet. Ideal for researching local businesses at scale.',
          public_workflow_created_at: '1673948552270',
          steps: [
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/google-maps.svg',
              automation_name: 'Google Maps',
            },
          ],
          categories: [
            {
              id: 1,
              name: 'Sales',
            },
          ],
        },
        {
          public_workflow_id: 81,
          public_workflow_name: 'Create ice breakers for Google Maps leads',
          public_workflow_description: 'Find leads from Google Maps and create interesting icebreakers on autopilot.',
          public_workflow_created_at: '1684831794979',
          steps: [
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/google-maps.svg',
              automation_name: 'Google Maps',
            },
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/seo.svg',
              automation_name: 'SEO meta tags',
            },
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/article-curator.svg',
              automation_name: 'Article scraper',
            },
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/ChatGPT.svg',
              automation_name: 'ChatGPT (GPT-3.5)',
            },
          ],
          categories: [
            {
              id: 4,
              name: 'ChatGPT',
            },
            {
              id: 1,
              name: 'Sales',
            },
          ],
        },
      ],
      prices: [
        {
          id: 28,
          unit_name: 'request',
          credit_price: 1,
          premium_credit_price: 0.04,
        },
      ],
      url: 'google-maps',
      meta_title: 'Google Maps Automation | Hexomatic',
      meta_description:
        'Perform Google Maps searches and get SERP results SERP results via our managed automation. No proxies or scraping required.',
      has_source: true,
      short_description: 'Perform Google Maps searches and get SERP results',
      long_description:
        'Perform Google Maps searches and get SERP results via our managed automation (0.04 premium credit per query to get 100 search results). No proxies or scraping required.',
      long_description_details:
        'Getting a list of locations is an essential part of market research. It is a complicated and time-consuming process and can not always give the best results.\n<br />Our Google search automation will help you perform Google Maps searches and get SERP data easily and quickly. No proxies or scraping recipes are required.\n<br />**Different ways to use our Google Maps automation:**\n<br />\n\n> * Via the Data input automation (using the Manual paste / List of inputs) to check a list of keywords and find SERP data.\n> * Via the Data input automation (using the Upload file / CSV file) to check a list of keywords in a local CSV file.\n> * Checking keywords and finding SERP data from your Hexomatic workflow.\n<br />\n\n**How to use our Google Maps automation:**\n<br />Step 1: Create a new blank workflow.\nStep 2: Add the Google Maps automation. Next, add the keyword, the targeted country, the device type, the search type, and the number of search results to be displayed.\nStep 3: Click continue to run or schedule the workflow.',
      icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/google-maps.svg',
      category: {
        id: 1,
        name: 'Scraping',
      },
      type: {
        id: 5,
        name: 'Premium automations',
      },
      inputs: 'any',
      outputs:
        '{"_maps_rank_group":"number","_maps_address_info_city":"text","_maps_address_info_zip":"number","_maps_address_info_region":"text","_maps_address_info_country_code":"text","_maps_rating_type":"text","_maps_rating_value":"text","_maps_rating_votes_count":"number","_maps_rating_rating_max":"number","_maps_place_id":"text","_maps_phone":"number","_maps_rank_absolute":"number","_maps_main_image":"url","_maps_work_hours":"text","_maps_feature_id":"text","_maps_cid":"number","_maps_latitude":"number","_maps_longitude":"number","_maps_domain":"url","_maps_title":"text","_maps_url":"url","_maps_snippet":"text","_maps_address":"text","_maps_address_info_borough":"text","_maps_address_info_address":"text","_maps_keyword":"text","_maps_category":"text"}',
      active: true,
      rate: 4.1,
    },
    {
      id: 122,
      name: 'Amazon product data',
      is_new: false,
      containedPublicWorkflows: [
        {
          public_workflow_id: 82,
          public_workflow_name: 'Create ecommerce product listings using Amazon as a data source',
          public_workflow_description:
            'Create factual product listings on autopilot. Simply specify a list of product names, and the workflow will create unique product titles, product descriptions and an FAQ by researching data from Amazon.',
          public_workflow_created_at: '1684833015078',
          steps: [
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/data-input.svg',
              automation_name: 'Data input',
            },
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/amazon.svg',
              automation_name: 'Amazon product search',
            },
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/amazon-product-data.svg',
              automation_name: 'Amazon product data',
            },
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/ChatGPT.svg',
              automation_name: 'ChatGPT (GPT-3.5)',
            },
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/ChatGPT.svg',
              automation_name: 'ChatGPT (GPT-3.5)',
            },
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/ChatGPT.svg',
              automation_name: 'ChatGPT (GPT-3.5)',
            },
          ],
          categories: [
            {
              id: 4,
              name: 'ChatGPT',
            },
            {
              id: 2,
              name: 'Marketing',
            },
          ],
        },
      ],
      prices: [
        {
          id: 119,
          unit_name: 'request',
          credit_price: 1,
          premium_credit_price: 0.3,
        },
      ],
      url: 'amazon-product-data',
      meta_title: 'Amazon Product Data | Amazon Product Data Search',
      meta_description:
        'Forget about wasting hours researching hundreds of products on Amazon. With Hexomatic you can find data about any amazon product in seconds.',
      has_source: true,
      short_description: 'Get detailed product page information for any Amazon product listing',
      long_description:
        'Get detailed product page information for any Amazon product listing. This automation takes an ASIN number and returns product page data including title, description, price and reviews.',
      long_description_details:
        'Amazon’s catalog boasts over 350 million products and beats Google as the first stop for product searches.\n<br />With our Amazon product data automation, you can get detailed product page information for any Amazon product listing. This automation takes an ASIN number and returns product page data including title, description, price and reviews.\n<br />**Different ways to use our Amazon product data automation:**\n<br />\n> * Via Data input automation (using the Manual paste / List of inputs) to perform searches.\n> * Via Data input automation (using the Upload file / CSV file) to perform searches in a local CSV file.\n> * Perform searches from your Hexomatic workflow.\n<br />\n\n**How to use our Amazon product data automation:**\n<br /> Step 1: Create a blank workflow and select Data input as the starting point.\nStep 2: Add the Amazon product search automation. Then insert the ASIN of the desired product, the language of the search, and the targeted country.\nStep 3: Click continue to run or schedule the workflow.\n<br />After running this automation, as an output you can get Amazon product ASIN, amazon product type, Amazon product rank group, product title, and more.\n',
      icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/amazon-product-data.svg',
      category: {
        id: 1,
        name: 'Scraping',
      },
      type: {
        id: 5,
        name: 'Premium automations',
      },
      inputs: 'any',
      outputs:
        '{"_amazon_product_asin":"text","_amazon_product_check_url":"url","_amazon_product_type":"text","_amazon_product_rank_group":"number","_amazon_product_rank_absolute":"number","_amazon_product_position":"text","_amazon_product_title":"text","_amazon_product_details":"text","_amazon_product_image_url":"url","_amazon_product_author":"text","_amazon_product_parent_asin":"text","_amazon_all_product_asins":"text","_amazon_product_price_from":"number","_amazon_product_price_to":"number","_amazon_product_currency":"text","_amazon_product_is_amazon_choice":"text","_amazon_product_rating_position":"text","_amazon_product_rating_type":"text","_amazon_product_rating_value":"number","_amazon_product_rating_votes_count":"number","_amazon_product_rating_max":"number","_amazon_product_is_newer_model_available":"text","_amazon_product_newer_model_title":"text","_amazon_product_newer_model_asin":"text","_amazon_product_newer_model_category":"text","_amazon_product_newer_model_url":"url","_amazon_product_images_list":"url","_amazon_product_publisher":"text","_amazon_product_language":"text","_amazon_product_file_size":"any","_amazon_product_word_wise":"text","_amazon_product_best_sellers_rank":"number","_amazon_product_customer_reviews":"text","_amazon_product_description":"text","_amazon_product_is_available":"text"}',
      active: true,
      rate: 4.1,
    },
    {
      id: 131,
      name: 'AI Sentiment analysis',
      is_new: true,
      containedPublicWorkflows: [],
      prices: [
        {
          id: 127,
          unit_name: 'units',
          credit_price: 0,
          premium_credit_price: 0.12,
        },
        {
          id: 128,
          unit_name: 'request',
          credit_price: 1,
          premium_credit_price: 0,
        },
      ],
      url: 'ai-sentiment-analysis',
      meta_title: 'Automate Sentiment Analysis - AI Automation',
      meta_description:
        'Analyze financial headlines, scraped content, product reviews, and UGC in minutes to turn data into actionable insights.',
      has_source: true,
      short_description:
        'Evaluate financial headlines, scraped content, product reviews, and user-generated content on autopilot with our Sentiment analysis automation',
      long_description:
        'Evaluate financial headlines, scraped content, product reviews, and user-generated content in minutes and without using any code or machine learning. \nSentiment analysis can be applied to any text or URL inside your workflow and be used to understand positive, negative or neutral sentiment at scale.\nConsumes 0.12 premium credits per 1000 characters.',
      long_description_details:
        'Customers constantly share their opinions and feelings toward companies on social media, in online reviews, and so on. At the end of the day, that is transformed into an overwhelming amount of unstructured data to analyze.\n<br />For keeping track of what customers say about your company, you need to turn to AI Sentiment analysis, helping to automatically identify the emotional tone in comments and get fast, real-time insights from large customer datasets.\n<br />Our Hexomatic AI Sentiment analysis automation enables you to evaluate financial headlines, scraped content, product reviews, and user-generated content in minutes and without using any code or machine learning. It can be applied to any text or URL inside your workflow and be used to understand positive, negative or neutral sentiment at scale.\n<br />**Different ways to use our AI Sentiment analysis automation:**\n<br />\n> * Directly, using the AI Sentiment analysis automation.\n> * Via Data input automation (using the list of inputs option) to analyze a list of URLs.\n> * Via Data input automation (using the Upload file/ CSV file/ TXT file option) to analyze a list of URLs in a local CSV or TXT file.\n> * To analyze URLs in your Hexomatic workflow. \n<br />\n\n**How to use our AI Sentiment analysis automation via Data input:**\n<br /> Step 1: Create a blank workflow and select Data input as the starting point.\nStep 2: Add AI Sentiment analysis automation, selecting Data input as the source.\nStep 3: Select the source type (URL/Text) and the language.\nStep 4: Click continue to run or schedule the workflow.',
      icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/sentiment-analysis.svg',
      category: {
        id: 21,
        name: 'AI services',
      },
      type: {
        id: 5,
        name: 'Premium automations',
      },
      inputs: 'any',
      outputs:
        '{"_document_sentiment":"text","_sentence_text_content":"text","_sentence_text_content_sentiment":"text"}',
      active: true,
      rate: 4.1,
    },
    {
      id: 74,
      name: 'Email discovery',
      is_new: false,
      containedPublicWorkflows: [
        {
          public_workflow_id: 23,
          public_workflow_name: 'Find email addresses and contact details for any domain in bulk',
          public_workflow_description:
            'Find email, social media and phone number contact details for a list of website in minutes.\nThis workflow looks for contact details on each website and on the web, then validates each email on autopilot.',
          public_workflow_created_at: '1631708742988',
          steps: [
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/data-input.svg',
              automation_name: 'Data input',
            },
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/emails-scraper.svg',
              automation_name: 'Emails scraper',
            },
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/email-discovery.svg',
              automation_name: 'Email discovery',
            },
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/email-validation.svg',
              automation_name: 'Email address validation',
            },
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/phone-number-scraper.svg',
              automation_name: 'Phone number scraper',
            },
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/social-links-scraper.svg',
              automation_name: 'Social links scraper',
            },
          ],
          categories: [
            {
              id: 1,
              name: 'Sales',
            },
          ],
        },
        {
          public_workflow_id: 49,
          public_workflow_name: 'Discover email addresses for a list of websites',
          public_workflow_description:
            'Accelerate your outreach by automatically finding email contact details for a list of domains.\nThis workflow taps into the web to find and validate email addresses for any website.',
          public_workflow_created_at: '1634284869744',
          steps: [
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/data-input.svg',
              automation_name: 'Data input',
            },
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/email-discovery.svg',
              automation_name: 'Email discovery',
            },
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/email-validation.svg',
              automation_name: 'Email address validation',
            },
          ],
          categories: [
            {
              id: 1,
              name: 'Sales',
            },
          ],
        },
        {
          public_workflow_id: 59,
          public_workflow_name: 'Discover Influencers contact details',
          public_workflow_description:
            'Meta description: Find influencers, bloggers, or publishers to promote your business in minutes.\nThis workflow looks for existing reviews on Google and finds contact details on autopilot.',
          public_workflow_created_at: '1643206232725',
          steps: [
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/google-search.png',
              automation_name: 'Google Search',
            },
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/emails-scraper.svg',
              automation_name: 'Emails scraper',
            },
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/email-discovery.svg',
              automation_name: 'Email discovery',
            },
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/social-links-scraper.svg',
              automation_name: 'Social links scraper',
            },
          ],
          categories: [
            {
              id: 2,
              name: 'Marketing',
            },
          ],
        },
        {
          public_workflow_id: 60,
          public_workflow_name: 'Find affiliates for your product or service',
          public_workflow_description:
            'Find affiliates to promote your affiliate marketing program in minutes.\nThis workflow looks for existing affiliate reviews on Google and finds contact details on autopilot.',
          public_workflow_created_at: '1643206272851',
          steps: [
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/google-search.png',
              automation_name: 'Google Search',
            },
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/emails-scraper.svg',
              automation_name: 'Emails scraper',
            },
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/social-links-scraper.svg',
              automation_name: 'Social links scraper',
            },
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/email-discovery.svg',
              automation_name: 'Email discovery',
            },
          ],
          categories: [
            {
              id: 2,
              name: 'Marketing',
            },
          ],
        },
        {
          public_workflow_id: 69,
          public_workflow_name: 'Find B2B leads from Google Maps',
          public_workflow_description:
            'Find local businesses or B2B prospects for any industry and geographic location on autopilot. This workflow enriches every prospect with contact details and social media profiles for easy outreach.',
          public_workflow_created_at: '1645536733167',
          steps: [
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/google-maps.svg',
              automation_name: 'Google Maps',
            },
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/email-discovery.svg',
              automation_name: 'Email discovery',
            },
          ],
          categories: [
            {
              id: 1,
              name: 'Sales',
            },
          ],
        },
      ],
      prices: [
        {
          id: 141,
          unit_name: 'request',
          credit_price: 1,
          premium_credit_price: 0,
        },
        {
          id: 142,
          unit_name: 'count',
          credit_price: 0,
          premium_credit_price: 0.16,
        },
      ],
      url: 'email-discovery',
      meta_title: 'Find Email Address | Email Finder',
      meta_description: 'Find email addresses for any website in minutes to supercharge your cold email outreach.',
      has_source: false,
      short_description: 'Discover email addresses found on the website or referenced on the internet',
      long_description:
        'Provide a list of domains and discover email addresses found on the website or referenced on the internet.\nThis automation consumes 0.16 premium credits per verified email.',
      long_description_details:
        'Finding emails of potential prospects can be sometimes difficult and time-consuming.\n<br />Our Email discovery automation will help you get email addresses found on the website or referenced on the internet. All you need to do is provide a list of domains, and the automation will return the discovered email addresses.\n<br />**Different ways to use our Email discovery automation:**\n<br />\n> * Via Data input automation (using the Manual paste / List of inputs) to check a list of domains.\n> * Via Data input automation (using the Upload file / CSV file) to check a list of domains in a local CSV file.\n<br />\n\n**How to use our Email discovery automation via Data input:**\n<br />Step 1: Create a blank workflow and select Data input as the starting point. Then, add the website URL to be checked.\nStep 2: Add the Email discovery automation, selecting the Data input as the source.\nStep 3: Click continue to run or schedule the workflow.',
      icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/email-discovery.svg',
      category: {
        id: 9,
        name: 'Research',
      },
      type: {
        id: 5,
        name: 'Premium automations',
      },
      inputs: 'url',
      outputs: '{"_discovered_email":"email address","_discovered_email_verified":"boolean"}',
      active: true,
      rate: 4.1,
    },
    {
      id: 34,
      name: 'DeepL Translate',
      is_new: true,
      containedPublicWorkflows: [],
      prices: [
        {
          id: 25,
          unit_name: 'character',
          credit_price: 0,
          premium_credit_price: 0.0009,
        },
        {
          id: 26,
          unit_name: 'request',
          credit_price: 1,
          premium_credit_price: 0,
        },
      ],
      url: 'deepl-translate',
      meta_title: 'DeepL Translate Automation | Hexomatic',
      meta_description:
        'Advanced machine translation is possible via the award-winning DeepL translation automation. DeepL Translate is featuring unique options including formal/informal language settings.',
      has_source: false,
      short_description: 'Perform an advanced machine translation via DeepL',
      long_description:
        'Perform an advanced machine translation via the award-winning DeepL service (0.0009 premium credit per character). Supports formal and informal cases. ',
      long_description_details:
        'Foreign markets are a great way to scale but translating content, creatives and documents at scale is time-consuming and complex.\n<br />DeepL Translate is one of the most advanced machine translation providers, featuring unique options including formal/informal language settings to fit your target audience.\n<br />DeepL provides a compromise between a human translation and a machine-based translation. With our DeepL automation, you can translate texts in nine different languages at a fraction of the cost of traditional translators in minutes.\n<br />**Different ways to use our DeepL Translate automation:**\n<br />\n> * Via Data input automation using (using the Manual paste / Text option) to translate an article or text fragment.\n> * Via Data input automation (using the Upload file / CSV file or TXT file option) to translate an entire file.\n> * Via Data input automation (using the File URL / CSV file or TXT file option) to translate a remotely stored file.\n> * Translating texts from inside your Hexomatic workflow.\n<br />\n\n**How to use the DeepL Translate automation via a Data input:**\n<br />Step 1: Create a blank workflow and select Data input as the starting point.\nStep 2: Via the Data input automation, choose upload a file or copy-paste the text you want to translate. \nStep 3: Add the DeepL Translate automation, selecting the Data input as the source. Select the source language, style (Automatic/ Formal/ Informal) and the target language.\nStep 4: Click continue to run or schedule the workflow.\n',
      icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/deepL.svg',
      category: {
        id: 12,
        name: 'Translation',
      },
      type: {
        id: 5,
        name: 'Premium automations',
      },
      inputs: 'text, any',
      outputs: 'null',
      active: true,
      rate: 4.1,
    },
    {
      id: 143,
      name: 'ChatGPT (GPT-3.5 Turbo 16K)',
      is_new: true,
      containedPublicWorkflows: [],
      prices: [
        {
          id: 143,
          unit_name: 'request',
          credit_price: 1,
          premium_credit_price: 0.00024,
        },
      ],
      url: 'chatgpt',
      meta_title: 'Summarize or Rewrite Scraped Content | ChatGPT Hexomatic',
      meta_description: 'Use the power of ChatGPT (GPT-3.5 Turbo 16K) inside your Hexomatic workflows.',
      short_description: 'Use the power of ChatGPT inside your Hexomatic workflows.',
      long_description:
        'Tap into the power of AI by creating your own prompts to perform ChatGPT tasks on the data you scraped or uploaded, or use our ready-made prompts to summarize, rewrite or improve data inside your workflow.',
      icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/ChatGPT.svg',
      category: {
        id: 21,
        name: 'AI services',
      },
      type: {
        id: 5,
        name: 'Premium automations',
      },
      inputs: 'url',
      outputs: '{"_prompt":"text", "_ChatGPT":"text"}',
      active: true,
      rate: 4.1,
    },
    {
      id: 138,
      name: 'Website Categorization',
      is_new: true,
      containedPublicWorkflows: [],
      prices: [
        {
          id: 137,
          unit_name: 'request',
          credit_price: 1,
          premium_credit_price: 0,
        },
        {
          id: 138,
          unit_name: 'units',
          credit_price: 0,
          premium_credit_price: 0.25,
        },
      ],
      url: 'website-categorization',
      meta_title: 'Website Categorization | Hexomatic',
      meta_description:
        'Great tool for categorizing leads, lists and businesses! Our website categorization automation analyzes a web page and returns a relevant list of categories',
      has_source: false,
      short_description: 'Categorize any website based on its URL instantly.',
      long_description:
        'Website categorization automation analyzes a webpage and returns a list of categories that apply to the content found on the page. Ideal for classifying prospects, listings, and companies. Supported language is English.',
      long_description_details:
        "Understanding the nature of a website is essential for effective prospecting, lead generation, and company analysis.\n<br />With Hexomatic's Website Categorization automation, anyone can instantly categorize any website based on the provided URL. This automation is great if you want to automatically classify websites without wasting your time on such boring and time-consuming task. You will no longer have to visit each website and guess the website category from the existing content as our automation will handle this task for you in quite a short period of time.\n<br />**Different ways to use our Website Categorization automation:**\n<br />\n>* Via Data input automation (using the Manual paste / List of inputs) to automatically classify websites in bulk.\n>* Via Data input automation (using the Upload file / CSV file / Image file) to categorize a list of website URLs in a local file.\n\n<br />**How to use our Website Categorization automation via Data input:**\n<br /> Step 1: Create a blank workflow and select Data input as the starting point.\nStep 2: Paste the website URLs (Manual paste) or upload the file of website URLs (Upload file) you want to categorize.\nStep 3: Search for the Website Categorization automation and add it to the workflow.\nStep 4: Choose the source as Data input.\nStep 5: Click continue to run or schedule the workflow.",
      icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/website-categorization.svg',
      category: {
        id: 21,
        name: 'AI services',
      },
      type: {
        id: 5,
        name: 'Premium automations',
      },
      inputs: 'url',
      outputs: '{"_content_classification_category":"text"}',
      active: true,
      rate: 4.1,
    },
    {
      id: 88,
      name: 'AI Audio transcription',
      is_new: false,
      containedPublicWorkflows: [],
      prices: [
        {
          id: 66,
          unit_name: 'request',
          credit_price: 1,
          premium_credit_price: 0.27,
        },
      ],
      url: 'ai-audio-transcription',
      meta_title: 'AI Audio to Text Converter  | Online Transcription',
      meta_description:
        'Automatically convert audio files into text and transcribe hours of audio in minutes. Our audio to text converter uses AI speech recognition to help you transcribe at scale.',
      short_description: 'With Hexomatic, you can transcribe hours of audio in minutes from a wide range of languages.',
      long_description:
        'Uses speech recognition capabilities to automatically convert audio files into text (* Each request is rounded up to the nearest increment of 15 seconds).',
      icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/google-speech-to-text.svg',
      category: {
        id: 21,
        name: 'AI services',
      },
      type: {
        id: 5,
        name: 'Premium automations',
      },
      inputs: 'url',
      outputs: '{"_audio_transcription":"url"}',
      active: true,
      rate: 4.1,
    },
    {
      id: 106,
      name: 'AI Document OCR',
      is_new: false,
      containedPublicWorkflows: [],
      prices: [
        {
          id: 99,
          unit_name: 'request',
          credit_price: 1,
          premium_credit_price: 0.1353,
        },
      ],
      url: 'ai-document-ocr',
      meta_title: 'PDF Text Extractor | AI Text Extractor',
      meta_description:
        'Detect and extract texts from documents via Google Vision AI! Our pdf and tiff extractor automation can recognize text from documents at scale in minutes. ',
      short_description: 'Detect and extract texts from documents via Google Vision AI.',
      long_description:
        ' The AI document OCR automation can recognize text from documents via Google Vision AI at scale in minutes. Supported document formats: .pdf, .tiff.',
      icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/ai-document-ocr.svg',
      category: {
        id: 21,
        name: 'AI services',
      },
      type: {
        id: 5,
        name: 'Premium automations',
      },
      inputs: 'url',
      outputs: '{"_text_from_document":"text"}',
      active: true,
      rate: 4.1,
    },
    {
      id: 91,
      name: 'AI Image OCR',
      is_new: false,
      containedPublicWorkflows: [
        {
          public_workflow_id: 70,
          public_workflow_name: 'Transcribe images in bulk',
          public_workflow_description:
            'Extract text from a list of images using AI and OCR, perfect for transcribing menus, signs or image based pdf documents.',
          public_workflow_created_at: '1645542209165',
          steps: [
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/data-input.svg',
              automation_name: 'Data input',
            },
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/text-from-image.svg',
              automation_name: 'AI Image OCR',
            },
          ],
          categories: [
            {
              id: 2,
              name: 'Marketing',
            },
          ],
        },
      ],
      prices: [
        {
          id: 70,
          unit_name: 'request',
          credit_price: 1,
          premium_credit_price: 0.1353,
        },
      ],
      url: 'ai-optical-character-recognition-ocr',
      meta_title: 'Image Text Extractor | AI Image Text Extractor',
      meta_description:
        'Extract texts from images via Google Vision AI! A number of formats are supported, including .jpeg, .png8, .png24, .png, .gif, .bmp, .webp, .raw, .ico, .jpg.',
      short_description: 'Extract texts from images via Google Vision AI.',
      long_description:
        'This automation taps into Google Vision AI, and can detect and extract texts on autopilot. A number of formats are supported, including .jpeg, .png8, .png24, .png, .gif, .bmp, .webp, .raw, .ico, .jpg.',
      icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/text-from-image.svg',
      category: {
        id: 21,
        name: 'AI services',
      },
      type: {
        id: 5,
        name: 'Premium automations',
      },
      inputs: 'url',
      outputs: '{"_text_from_image":"text"}',
      active: true,
      rate: 4.1,
    },
    {
      id: 85,
      name: 'AI Text to speech',
      is_new: false,
      containedPublicWorkflows: [],
      prices: [
        {
          id: 58,
          unit_name: 'request',
          credit_price: 1,
          premium_credit_price: 0,
        },
        {
          id: 59,
          unit_name: 'character',
          credit_price: 0,
          premium_credit_price: 0.0008,
        },
      ],
      url: 'ai-text-to-speech',
      meta_title: 'Text to Speech Generator | Automate Text to Speech',
      meta_description: 'Tap into the growing voice search market with automatic text to speech conversion using AI.',
      short_description: 'Perform text to speech conversion via Google Text-to-Speech',
      long_description:
        'Enables text to be converted into speech sounds imitative of the human voice via Google Text-to-Speech.',
      icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/google-text-to-speech.svg',
      category: {
        id: 21,
        name: 'AI services',
      },
      type: {
        id: 5,
        name: 'Premium automations',
      },
      inputs: 'text',
      outputs: '{"_text_to_speech":"url"}',
      active: true,
      rate: 4.1,
    },
    {
      id: 89,
      name: 'AI image labeling',
      is_new: false,
      containedPublicWorkflows: [],
      prices: [
        {
          id: 61,
          unit_name: 'request',
          credit_price: 1,
          premium_credit_price: 0.1353,
        },
      ],
      url: 'ai-image-labeling',
      meta_title: 'Ai Image Labeling | Extract Image Labels',
      meta_description:
        'Extract image labels via Google Vision AI with our AI image labeling automation. Supported formats include .jpeg, .png, .gif, jpg., .jpeg, and more. ',
      short_description: 'Extract image labels via Google Vision AI',
      long_description:
        'Detects and extracts image labels via Google Vision AI. Supported image formats: .jpeg, .png8, .png24, .png, .gif, .bmp, .webp, .raw, .ico, .pdf, .tiff, .jpg.',
      icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/google-vision-ai.svg',
      category: {
        id: 21,
        name: 'AI services',
      },
      type: {
        id: 5,
        name: 'Premium automations',
      },
      inputs: 'url',
      outputs: '{"_label_description":"text","_label_score":"number"}',
      active: true,
      rate: 4.1,
    },
    {
      id: 90,
      name: 'AI image safety',
      is_new: false,
      containedPublicWorkflows: [],
      prices: [
        {
          id: 69,
          unit_name: 'request',
          credit_price: 1,
          premium_credit_price: 0.1353,
        },
      ],
      url: 'ai-image-safety',
      meta_title: 'Ai Image Safety | Image Safety Checker',
      meta_description:
        'Hexomatic automation allows you to perform image safety checks via Google Vision AI. Check here to explore.',
      short_description: 'Perform image safety checks via Google Vision AI',
      long_description:
        'Perform image safety checks via Google Vision AI. Supported image formats: .jpeg, .png8, .png24, .png, .gif, .bmp, .webp, .raw, .ico, .pdf, .tiff, .jpg.',
      icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/image-safety.svg',
      category: {
        id: 21,
        name: 'AI services',
      },
      type: {
        id: 5,
        name: 'Premium automations',
      },
      inputs: 'url',
      outputs:
        '{"_image_safety_is_adult":"text","_image_safety_is_spoof":"text","_image_safety_is_medical":"text","_image_safety_is_violence":"text","_image_safety_is_racy":"text"}',
      active: true,
      rate: 4.1,
    },
    {
      id: 94,
      name: 'AI logo detection',
      is_new: true,
      containedPublicWorkflows: [],
      prices: [
        {
          id: 74,
          unit_name: 'request',
          credit_price: 1,
          premium_credit_price: 0.1353,
        },
      ],
      url: 'ai-logo-detection',
      meta_title: 'Logo Detection | AI Logo Detection',
      meta_description:
        'This automation helps you discover product logos within an image. Multiple image formats supported.',
      short_description: 'Discover product logos within an image via Google Vision AI',
      long_description:
        'Discover product logos within an image via Google Vision AI. Supported image formats: .jpeg, .png8, .png24, .png, .gif, .bmp, .webp, .raw, .ico, .pdf, .tiff, .jpg ',
      icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/ai-logo-detection.svg',
      category: {
        id: 21,
        name: 'AI services',
      },
      type: {
        id: 5,
        name: 'Premium automations',
      },
      inputs: 'url',
      outputs: '{"_logo_detector":"url"}',
      active: true,
      rate: 4.1,
    },
    {
      id: 75,
      name: 'Amazon seller finder',
      is_new: false,
      containedPublicWorkflows: [
        {
          public_workflow_id: 22,
          public_workflow_name: 'Check competitors competing for Amazon listings',
          public_workflow_description:
            'Find competing sellers and their offers for a list of Amazin ASIN numbers on autopilot to stay competitive and catch listing highjackers.',
          public_workflow_created_at: '1631708736935',
          steps: [
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/data-input.svg',
              automation_name: 'Data input',
            },
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/amazon-seller.svg',
              automation_name: 'Amazon seller finder',
            },
          ],
          categories: [
            {
              id: 3,
              name: 'Research',
            },
          ],
        },
      ],
      prices: [
        {
          id: 47,
          unit_name: 'request',
          credit_price: 1,
          premium_credit_price: 0.06,
        },
      ],
      url: 'amazon-seller-finder',
      meta_title: 'Amazon Seller Finder | Find Amazon Seller',
      meta_description:
        'Our Amazon seller finder automation will help you get information about the Amazon sellers via Amazon standard identification numbers.',
      short_description: 'Perform Amazon searches and get information about the sellers',
      long_description: 'Input a list of Amazon standard identification numbers and get information about the sellers.',
      icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/amazon-seller.svg',
      category: {
        id: 9,
        name: 'Research',
      },
      type: {
        id: 5,
        name: 'Premium automations',
      },
      inputs: 'text',
      outputs:
        '{"_amazon_seller_type":"text","_amazon_seller_rank_group":"number","_amazon_seller_rank_absolute":"number","_amazon_seller_position":"text","_amazon_seller_name":"text","_amazon_seller_url":"url","_amazon_seller_ships_from":"text","_amazon_seller_condition":"text","_amazon_seller_condition_description":"text","_amazon_seller_price_current":"number","_amazon_seller_price_regular":"number","_amazon_seller_price_max_value":"number","_amazon_seller_price_currency":"text","_amazon_seller_price_displayed_price":"number","_amazon_seller_rating_type":"text","_amazon_seller_rating_position":"text","_amazon_seller_rating_rating_type":"text","_amazon_seller_rating_value":"number","_amazon_seller_rating_votes_count":"number","_amazon_seller_rating_max":"number","_free_delivery_date_from":"number","_free_delivery_date_to":"number","_fastest_delivery_date_from":"number","_fastest_delivery_date_to":"number","_amazon_seller_price_is_price_range":"boolean"}',
      active: true,
      rate: 4.1,
    },
    {
      id: 95,
      name: 'SEO backlink explorer',
      is_new: false,
      containedPublicWorkflows: [
        {
          public_workflow_id: 42,
          public_workflow_name: 'Find all the pages that link to the top ranking websites',
          public_workflow_description:
            'Automate backlink research at scale by reverse-engineering the pages that link to the top ranking websites for any keyword on Google. This workflow creates a comprehensive report containing the best backlinks and their metrics.',
          public_workflow_created_at: '1634216937457',
          steps: [
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/google-search.png',
              automation_name: 'Google Search',
            },
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/seo-backlink.svg',
              automation_name: 'SEO backlink explorer',
            },
          ],
          categories: [
            {
              id: 2,
              name: 'Marketing',
            },
            {
              id: 3,
              name: 'Research',
            },
          ],
        },
        {
          public_workflow_id: 41,
          public_workflow_name: 'Find all the pages that link to your competitors',
          public_workflow_description:
            'Automate backlink research at scale by reverse-engineering the pages that link to a list of domains or URLs. This workflow creates a comprehensive report containing the best backlinks and their metrics.',
          public_workflow_created_at: '1634214539732',
          steps: [
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/data-input.svg',
              automation_name: 'Data input',
            },
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/seo-backlink.svg',
              automation_name: 'SEO backlink explorer',
            },
          ],
          categories: [
            {
              id: 2,
              name: 'Marketing',
            },
            {
              id: 3,
              name: 'Research',
            },
          ],
        },
      ],
      prices: [
        {
          id: 79,
          unit_name: 'request',
          credit_price: 1,
          premium_credit_price: 2.5,
        },
      ],
      url: 'seo-backlink-explorer',
      meta_title: 'Backlink Explorer | Check Pages for Backlinks',
      meta_description:
        'Uncover all your competitors best SEO backlinks in seconds with the backlink explorer automation. SEO has never been easier.',
      short_description: 'Find pages linking to any domain or a webpage',
      long_description:
        'Find existing backlinks (and their SEO metrics) for an entire website or a specific webpage (2.5 premium credit per query to get each 1000 results).',
      icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/seo-backlink.svg',
      category: {
        id: 23,
        name: 'SEO',
      },
      type: {
        id: 5,
        name: 'Premium automations',
      },
      inputs: 'url',
      outputs:
        '{"_backlink_domain_from":"url","_backlink_url_from":"url","_backlink_url_from_https":"text","_backlink_url_to":"url","_backlink_url_to_https":"text","_backlink_tld_from":"text","_backlink_is_new":"text","_backlink_is_lost":"text","_backlink_page_from_rank":"number","_backlink_domain_from_rank":"number","_backlink_domain_from_platform_type":"text","_backlink_domain_from_is_ip":"text","_backlink_domain_from_ip":"number","_backlink_page_from_external_links":"number","_backlink_page_from_internal_links":"number","_backlink_page_from_size":"number","_backlink_page_from_encoding":"text","_backlink_page_from_language":"text","_backlink_page_from_title":"text","_backlink_page_from_status_code":"number","_backlink_first_seen":"number","_backlink_prev_seen":"number","_backlink_last_seen":"number","_backlink_item_type":"text","_backlink_attributes":"any","_backlink_dofollow":"text","_backlink_original":"text","_backlink_alt":"text","_backlink_anchor":"text","_backlink_text_pre":"text","_backlink_text_post":"text","_backlink_semantic_location":"text","_backlink_links_count":"number","_backlink_group_count":"number","_backlink_is_broken":"text","_backlink_url_to_status_code":"number"}',
      active: true,
      rate: 4.1,
    },
    {
      id: 107,
      name: 'SEO backlink intelligence',
      is_new: false,
      containedPublicWorkflows: [],
      prices: [
        {
          id: 100,
          unit_name: 'request',
          credit_price: 1,
          premium_credit_price: 2,
        },
      ],
      url: 'seo-backlink-intelligence',
      meta_title: 'Backlink Analyzer | Check SEO Backlinks',
      meta_description:
        'Get SEO metrics for any page or domain including number of referring domains, referring backlinks, types of backlinks and more with our backlink analyzer automation.',
      short_description: 'Get top level off-page SEO metrics for any page or domain',
      long_description:
        'Get SEO metrics for any page or domain including number of referring domains, referring backlinks, types of backlinks and more.',
      icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/seo-backlink-intelligence.svg',
      category: {
        id: 23,
        name: 'SEO',
      },
      type: {
        id: 5,
        name: 'Premium automations',
      },
      inputs: 'url',
      outputs:
        '{"_backlink_intelligence_first_seen":"number","_backlink_intelligence_lost_date":"number","_backlink_intelligence_internal_links_count":"number","_backlink_intelligence_external_links_count":"number","_backlink_intelligence_broken_backlinks":"number","_backlink_intelligence_broken_pages":"number","_backlink_intelligence_referring_domains":"number","_backlink_intelligence_referring_main_domains":"number","_backlink_intelligence_referring_ips":"number","_backlink_intelligence_referring_subnets":"number","_backlink_intelligence_referring_pages":"number","_backlink_intelligence_referring_links_tld":"text","_backlink_intelligence_rank":"number","_backlink_intelligence_referring_links_types":"text","_backlink_intelligence_referring_links_attributes":"text","_backlink_intelligence_referring_links_platform_types":"text","_backlink_intelligence_referring_links_semantic_locations":"text","_backlink_intelligence_backlinks":"number","_backlink_intelligence_crawled_pages":"number","_backlink_intelligence_info_server":"text","_backlink_intelligence_info_cms":"any","_backlink_intelligence_info_platform_type":"text","_backlink_intelligence_info_ip_address":"number","_backlink_intelligence_info_is_ip":"text"}',
      active: true,
      rate: 4.1,
    },
    {
      id: 96,
      name: 'SEO referring domains',
      is_new: false,
      containedPublicWorkflows: [
        {
          public_workflow_id: 52,
          public_workflow_name: 'Research backlink partners & contact details via list',
          public_workflow_description:
            'Automate backlink research at scale by reverse engineering SEO backlink partners for a list of websites. This workflow creates a comprehensive report containing the best backlinks and contact details for each publisher.',
          public_workflow_created_at: '1636101240353',
          steps: [
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/data-input.svg',
              automation_name: 'Data input',
            },
            {
              automation_icon_url:
                'https://storage.googleapis.com/hexomatic-automation-icons/seo-referring-domains.svg',
              automation_name: 'SEO referring domains',
            },
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/social-links-scraper.svg',
              automation_name: 'Social links scraper',
            },
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/emails-scraper.svg',
              automation_name: 'Emails scraper',
            },
          ],
          categories: [
            {
              id: 2,
              name: 'Marketing',
            },
            {
              id: 3,
              name: 'Research',
            },
          ],
        },
        {
          public_workflow_id: 43,
          public_workflow_name: 'Research backlink partners & contact details via keyword',
          public_workflow_description:
            'Automate backlink research at scale by reverse-engineering the SEO backlink partners that power the top ranking websites for any keyword on Google. This workflow creates a comprehensive report containing the best backlinks and contact details for each publisher.',
          public_workflow_created_at: '1634216945690',
          steps: [
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/google-search.png',
              automation_name: 'Google Search',
            },
            {
              automation_icon_url:
                'https://storage.googleapis.com/hexomatic-automation-icons/seo-referring-domains.svg',
              automation_name: 'SEO referring domains',
            },
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/social-links-scraper.svg',
              automation_name: 'Social links scraper',
            },
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/emails-scraper.svg',
              automation_name: 'Emails scraper',
            },
          ],
          categories: [
            {
              id: 2,
              name: 'Marketing',
            },
            {
              id: 3,
              name: 'Research',
            },
          ],
        },
        {
          public_workflow_id: 40,
          public_workflow_name: 'Find domains linking to the top ranking sites for a keyword',
          public_workflow_description:
            'Automate your backlink research at scale by reverse-engineering the referring domains that link to the top ranking websites for any keyword on Google. This workflow creates a comprehensive report containing the best backlink partners and their metrics.',
          public_workflow_created_at: '1634214531350',
          steps: [
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/google-search.png',
              automation_name: 'Google Search',
            },
            {
              automation_icon_url:
                'https://storage.googleapis.com/hexomatic-automation-icons/seo-referring-domains.svg',
              automation_name: 'SEO referring domains',
            },
          ],
          categories: [
            {
              id: 2,
              name: 'Marketing',
            },
            {
              id: 3,
              name: 'Research',
            },
          ],
        },
        {
          public_workflow_id: 39,
          public_workflow_name: 'Find domains that link to your competitors',
          public_workflow_description:
            'Automate your backlink research at scale by reverse-engineering your competitors referring domains. This workflow creates a comprehensive SEO report containing the best backlink partners and their metrics.',
          public_workflow_created_at: '1634214523074',
          steps: [
            {
              automation_icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/data-input.svg',
              automation_name: 'Data input',
            },
            {
              automation_icon_url:
                'https://storage.googleapis.com/hexomatic-automation-icons/seo-referring-domains.svg',
              automation_name: 'SEO referring domains',
            },
          ],
          categories: [
            {
              id: 2,
              name: 'Marketing',
            },
            {
              id: 3,
              name: 'Research',
            },
          ],
        },
      ],
      prices: [
        {
          id: 80,
          unit_name: 'request',
          credit_price: 1,
          premium_credit_price: 2.5,
        },
      ],
      url: 'seo-referring-domains',
      meta_title: 'Check Referring Domains | Find SEO backlinks',
      meta_description:
        'Learn how to find all the referring domains that link to an entire website or a specific webpage at scale in minutes using this automation.',
      short_description: 'Find referring domains linking to any domain or webpage',
      long_description:
        'Find all the referring domains (and their SEO metrics) that link to an entire website or a specific webpage (2.5 premium credit per query to get each 1000 results).',
      icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/seo-referring-domains.svg',
      category: {
        id: 23,
        name: 'SEO',
      },
      type: {
        id: 5,
        name: 'Premium automations',
      },
      inputs: 'url',
      outputs:
        '{"_seo_referring_domain":"url","_seo_referring_domain_rank":"number","_seo_referring_pages":"number","_seo_referring_links_tld":"text","_seo_referring_links_types":"text","_seo_referring_links_attributes":"text","_seo_referring_links_platform_types":"text","_seo_referring_links_semantic_locations":"text","_seo_referring_domain_backlinks":"number","_seo_referring_domain_first_seen":"number","_seo_referring_domain_broken_backlinks":"number","_seo_referring_domain_broken_pages":"number","_seo_referring_main_domains":"number","_seo_referring_ips":"number","_seo_referring_subnets":"number","_seo_referring_domain_lost_date":"text"}',
      active: true,
      rate: 4.1,
    },
    {
      id: 135,
      name: 'Amazon product reviews',
      is_new: true,
      containedPublicWorkflows: [],
      prices: [
        {
          id: 133,
          unit_name: 'request',
          credit_price: 1,
          premium_credit_price: 0.36,
        },
      ],
      url: 'amazon-product-reviews',
      meta_title: 'Amazon Product Reviews | Extract Amazon Product Reviews',
      meta_description:
        'Do you need reviews for any particular amazon product? Our automation will return a list of product reviews, including the rating of the product, and more.',
      short_description: 'Extract Amazon product reviews at scale with Hexomatic',
      long_description:
        'Extract Amazon product reviews at scale right from your Hexomatic workflow in a few clicks. Insert the ASIN of the desired product, and Hexomatic will return a list of product reviews, including the rating of the product, full review texts, and more. This automation consumes 0.36 premium credits per batch of 10 results.',
      icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/amazon_product_reviews.svg',
      category: {
        id: 1,
        name: 'Scraping',
      },
      type: {
        id: 5,
        name: 'Premium automations',
      },
      inputs: 'text',
      outputs:
        '{"_amazon_product_reviews_asin":"text","_amazon_product_reviews_title":"text","_amazon_product_reviews_url":"url","_amazon_product_reviews_review_text":"text","_amazon_product_reviews_publication_date":"number","_amazon_product_reviews_rank_group":"number","_amazon_product_reviews_rank_absolute":"number","_amazon_product_reviews_position":"text","_amazon_product_reviews_xpath":"text","_amazon_product_reviews_verified":"boolean","_amazon_product_reviews_subtitle":"text","_amazon_product_reviews_helpful_votes":"number","_amazon_product_reviews_rating_type":"text","_amazon_product_reviews_rating_value":"email address","_amazon_product_reviews_rating_votes_count":"number","_amazon_product_reviews_rating_rating_max":"number","_amazon_product_reviews_images_type":"text","_amazon_product_reviews_images_alt":"text","_amazon_product_reviews_images_url":"url","_amazon_product_reviews_images_image_url":"url","_amazon_product_reviews_videos_type":"text","_amazon_product_reviews_videos_source":"url","_amazon_product_reviews_videos_preview":"url"}',
      active: true,
      rate: 4.1,
    },
    {
      id: 71,
      name: 'Amazon product search',
      is_new: false,
      containedPublicWorkflows: [],
      prices: [
        {
          id: 43,
          unit_name: 'request',
          credit_price: 1,
          premium_credit_price: 0.09,
        },
      ],
      url: 'amazon-product-search',
      meta_title: 'Amazon Product Search | Hexomatic Automations',
      meta_description:
        'This automation performs Amazon product searches and provides you with results via our managed automation. No proxies or scraping recipes required.',
      short_description: 'Perform product searches on Amazon',
      long_description:
        'Perform Amazon searches and get results via our managed automation (0.09 premium credit per query to get 100 search results). No proxies or scraping recipes required.',
      icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/amazon.svg',
      category: {
        id: 1,
        name: 'Scraping',
      },
      type: {
        id: 5,
        name: 'Premium automations',
      },
      inputs: 'text',
      outputs:
        '{"_amazon_keyword":"text","_amazon_type":"text","_amazon_rank_group":"number","_amazon_rank_absolute":"number","_amazon_xpath":"text","_amazon_image_url":"url","_amazon_domain":"url","_amazon_title":"text","_amazon_url":"url","_amazon_price_from":"number","_amazon_currency":"text","_amazon_data_asin":"text","_amazon_rating_type":"text","_amazon_rating_position":"text","_amazon_rating_rating_type":"text","_amazon_rating_value":"number","_amazon_rating_votes_count":"number","_amazon_rating_rating_max":"number","_is_amazon_choice":"boolean","_amazon_is_best_seller":"boolean"}',
      active: true,
      rate: 4.1,
    },
    {
      id: 87,
      name: 'Baidu search',
      is_new: false,
      containedPublicWorkflows: [],
      prices: [
        {
          id: 64,
          unit_name: 'request',
          credit_price: 1,
          premium_credit_price: 0.04,
        },
      ],
      url: 'baidu-search',
      meta_title: 'Baidu Search | Perform Baidu Search Automation',
      meta_description:
        'Perform Baidu searches with Hexomatic automations and get results via our managed automation. No proxies or scraping recipes required.',
      short_description: 'Perform Baidu searches and get SERP results',
      long_description:
        'Perform Baidu searches and get results via our managed automation (0.04 premium credit per query to get 100 search results). No proxies or scraping recipes required.',
      icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/baidu.svg',
      category: {
        id: 1,
        name: 'Scraping',
      },
      type: {
        id: 5,
        name: 'Premium automations',
      },
      inputs: 'any',
      outputs:
        '{"_baidu_keyword":"text","_baidu_url":"url","_baidu_description":"text","_baidu_domain":"url","_baidu_title":"text","_baidu_rank_group":"number","_baidu_rank_absolute":"number","_baidu_breadcrumb":"text"}',
      active: true,
      rate: 4.1,
    },
    {
      id: 84,
      name: 'Bing search',
      is_new: false,
      containedPublicWorkflows: [],
      prices: [
        {
          id: 57,
          unit_name: 'request',
          credit_price: 1,
          premium_credit_price: 0.04,
        },
      ],
      url: 'bing-search',
      meta_title: 'Bing Search | Perform Bing Search Automation',
      meta_description:
        'Perform Bing searches with Hexomatic automations and get results via our managed automation. No proxies or scraping recipes required.',
      short_description: 'Perform Bing searches and get SERP data',
      long_description:
        'Perform Bing searches and get results via our managed automation (0.04 premium credit per query to get 100 search results). No proxies or scraping recipes required.',
      icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/bing.svg',
      category: {
        id: 1,
        name: 'Scraping',
      },
      type: {
        id: 5,
        name: 'Premium automations',
      },
      inputs: 'any',
      outputs:
        '{"_bing_title":"text","_bing_domain":"url","_bing_url":"url","_bing_description":"text","_bing_rank_group":"number","_bing_rank_absolute":"number","_bing_breadcrumb":"url"}',
      active: true,
      rate: 4.1,
    },
    {
      id: 125,
      name: 'Google BigQuery',
      is_new: true,
      containedPublicWorkflows: [],
      prices: [
        {
          id: 122,
          unit_name: 'query_data',
          credit_price: 0,
          premium_credit_price: 6e-10,
        },
        {
          id: 123,
          unit_name: 'request',
          credit_price: 10,
          premium_credit_price: 0,
        },
      ],
      url: 'google-bigquery',
      meta_title: 'Discover and access unique and valuable datasets | Hexomatic',
      meta_description:
        'Get unique and valuable datasets from Google, public, or commercial providers. Use Hexomatic automations to scale up your business.',
      short_description:
        'Discover and access unique and valuable datasets from Google, public, or commercial providers',
      long_description:
        'Discover and access unique and valuable datasets from Google, public, or commercial providers inside your workflow and use this as a starting point for automations. Credits are consumed based on the data scanned by your query and the number of bytes processed.',
      icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/google-bigquery.svg',
      category: {
        id: 1,
        name: 'Scraping',
      },
      type: {
        id: 5,
        name: 'Premium automations',
      },
      inputs: 'any',
      outputs: 'null',
      active: true,
      rate: 4.1,
    },
    {
      id: 32,
      name: 'Google News',
      is_new: false,
      containedPublicWorkflows: [],
      prices: [
        {
          id: 56,
          unit_name: 'request',
          credit_price: 1,
          premium_credit_price: 0.04,
        },
      ],
      url: 'google-news',
      meta_title: 'Google News Automation | Hexomatic',
      meta_description:
        'Perform Google News searches and get SERP results via our managed automation. No proxies or scraping recipes required.',
      short_description: 'Perform Google News searches and get SERP results',
      long_description:
        'Perform Google News searches and get SERP results via our managed automation. No proxies or scraping recipes required.',
      icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/google-news.svg',
      category: {
        id: 1,
        name: 'Scraping',
      },
      type: {
        id: 5,
        name: 'Premium automations',
      },
      inputs: 'any',
      outputs:
        '{"_news_keyword":"text","_news_title":"text","_news_rank_group":"number","_news_rank_absolute":"number","_top_stories_source":"url","_top_stories_domain":"url","_top_stories_title":"text","_top_stories_date":"number","_top_stories_amp_version":"boolean","_top_stories_timestamp":"number","_top_stories_url":"url"}',
      active: true,
      rate: 4.1,
    },
    {
      id: 105,
      name: 'Google image search',
      is_new: false,
      containedPublicWorkflows: [],
      prices: [
        {
          id: 98,
          unit_name: 'request',
          credit_price: 1,
          premium_credit_price: 0.04,
        },
      ],
      url: 'google-image-search',
      meta_title: 'Google Image Search Automation | Hexomatic',
      meta_description:
        "Get google image keyword, rank group, title, subtitle, alt text, url and much more via Hexomatic's Google Image Search automation.",
      short_description: 'Perform Google image searches and get SERP data',
      long_description:
        'Perform Google image searches and get results via our managed automation (0.04 premium credit per query to get 100 search results). No proxies or scraping recipes required.',
      icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/google-image-search.svg',
      category: {
        id: 1,
        name: 'Scraping',
      },
      type: {
        id: 5,
        name: 'Premium automations',
      },
      inputs: 'any',
      outputs:
        '{"_google_image_keyword":"text","_google_image_rank_group":"number","_google_image_rank_absolute":"number","_google_image_xpath":"text","_google_image_title":"text","_google_image_sub_title":"text","_google_image_alt":"text","_google_image_url":"url","_google_image_source_url":"url","_google_image_encoded_url":"url"}',
      active: true,
      rate: 4.1,
    },
    {
      id: 120,
      name: 'Google seller',
      is_new: false,
      containedPublicWorkflows: [],
      prices: [
        {
          id: 114,
          unit_name: 'request',
          credit_price: 1,
          premium_credit_price: 0.2,
        },
      ],
      url: 'google-seller',
      meta_title: 'Google Seller Automation | Get Competing Merchant Data',
      meta_description:
        "Google seller automation allows to input a list of Google product IDs and get competing sellers' pricing data. Try our easy to use automation and stand out from your competitors.",
      short_description: 'Get competing merchant data for any product ID',
      long_description: 'Input a list of Google product IDs to get pricing data of all competing sellers.',
      icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/google-seller.svg',
      category: {
        id: 1,
        name: 'Scraping',
      },
      type: {
        id: 5,
        name: 'Premium automations',
      },
      inputs: 'any',
      outputs:
        '{"_google_seller_product_id":"number","_google_seller_rank_group":"number","_google_seller_tax":"number","_google_seller_total_price":"number","_google_seller_currency":"text","_google_seller_name":"text","_google_seller_rating_type":"text","_google_seller_rating_position":"text","_google_seller_rating_rating_type":"text","_google_seller_rating_value":"number","_google_seller_rating_votes_count":"number","_google_seller_rating_max":"number","_google_seller_rank_absolute":"number","_google_seller_shop_ad_aclk":"text","_google_seller_position":"text","_google_seller_xpath":"text","_google_seller_domain":"url","_google_seller_title":"text","_google_seller_description":"text","_google_seller_url":"any","_google_seller_base_price":"number"}',
      active: true,
      rate: 4.1,
    },
    {
      id: 118,
      name: 'Google shopping automation',
      is_new: false,
      containedPublicWorkflows: [],
      prices: [
        {
          id: 108,
          unit_name: 'request',
          credit_price: 1,
          premium_credit_price: 0.1,
        },
      ],
      url: 'google-shopping',
      meta_title: 'Google Shopping Automation | Hexomatic',
      meta_description:
        'Perform Google shopping searches and get results instantly with our managed automation platform. Google Shopping is easier with Hexomatic.',
      short_description: 'Perform Google shopping searches and get SERP data',
      long_description:
        'Perform Google shopping searches and get results via our managed automation (0.1 premium credit per query to get 100 search results). No proxies or scraping recipes required.',
      icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/google-shopping.svg',
      category: {
        id: 1,
        name: 'Scraping',
      },
      type: {
        id: 5,
        name: 'Premium automations',
      },
      inputs: 'any',
      outputs:
        '{"_google_product_shopping_keyword":"number","_google_product_shopping_rank_group":"number","_google_product_shopping_tags":"text","_google_product_shopping_price":"number","_google_product_shopping_currency":"text","_google_product_shopping_product_id":"number","_google_product_shopping_seller":"text","_google_product_shopping_reviews_count":"number","_google_product_shopping_is_best_match":"text","_google_product_shopping_additional_specifications_eto":"number","_google_product_shopping_product_rating_type":"text","_google_product_shopping_product_rating_position":"text","_google_product_shopping_rank_absolute":"number","_google_product_shopping_product_rating_value":"number","_google_product_shopping_product_rating_votes_count":"number","_google_product_shopping_shop_rating":"number","_google_product_shopping_product_images":"url","_google_product_shopping_delivery_message":"text","_google_product_shopping_delivery_price_current":"number","_google_product_shopping_delivery_price_regular":"number","_google_product_shopping_delivery_price_max_value":"number","_google_product_shopping_delivery_price_currency":"text","_google_product_shopping_delivery_is_price_range":"text","_google_product_shopping_position":"text","_google_product_shopping_delivery_price_displayed_price":"text","_google_product_shopping_xpath":"text","_google_product_shopping_domain":"url","_google_product_shopping_title":"text","_google_product_shopping_description":"text","_google_product_shopping_url":"any","_google_product_shopping_shopping_url":"url"}',
      active: true,
      rate: 4.1,
    },
    {
      id: 126,
      name: 'Tripadvisor Search',
      is_new: true,
      containedPublicWorkflows: [],
      prices: [
        {
          id: 124,
          unit_name: 'request',
          credit_price: 1,
          premium_credit_price: 0.15,
        },
      ],
      url: 'tripadvisor-search',
      meta_title: 'Tripadvisor Search Automation | Hexomatic',
      meta_description:
        'Combine and analyze the Tripadvisor data to develop new services for your business with our Tripadvisor automation. Tripadvisor Search is easier with Hexomatic.',
      short_description: 'Get data on Tripadvisor listings',
      long_description:
        'Get data on businesses listed on the Tripadvisor platform, including business name and rating.',
      icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/tripadvisor.svg',
      category: {
        id: 1,
        name: 'Scraping',
      },
      type: {
        id: 5,
        name: 'Premium automations',
      },
      inputs: 'any',
      outputs:
        '{"_tripadvisor_keyword":"text","_tripadvisor_rank_group":"number","_tripadvisor_rating_max":"number","_tripadvisor_rank_absolute":"number","_tripadvisor_title":"text","_tripadvisor_url_path":"url","_tripadvisor_is_sponsored":"text","_tripadvisor_reviews_count":"number","_tripadvisor_rating_type":"text","_tripadvisor_rating_value":"number","_tripadvisor_rating_votes_count":"number"}',
      active: true,
      rate: 4.1,
    },
    {
      id: 128,
      name: 'Trustpilot Search',
      is_new: true,
      containedPublicWorkflows: [],
      prices: [
        {
          id: 125,
          unit_name: 'request',
          credit_price: 1,
          premium_credit_price: 0.15,
        },
      ],
      url: 'trustpilot-search',
      meta_title: 'Trustpilot Search Automation | Hexomatic',
      meta_description:
        'Get data on businesses listed on Trustpilot, including reviews and company details with our Trustpilot search automation.',
      short_description: 'Get data on businesses listed on Trustpilot, including reviews and company details',
      long_description:
        'Get data on businesses listed on Trustpilot, including reviews and company details.Tap into one of the largest business review directories as a new source of data or B2B leads featuring business data and reviews for a wide range of categories.',
      icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/trustpilot.svg',
      category: {
        id: 1,
        name: 'Scraping',
      },
      type: {
        id: 5,
        name: 'Premium automations',
      },
      inputs: 'any',
      outputs:
        '{"_trustpilot_search_keyword":"text","_trustpilot_search_type":"text","_trustpilot_search_rank_group":"number","_trustpilot_search_rank_absolute":"number","_trustpilot_search_title":"text","_trustpilot_search_domain":"url","_trustpilot_search_url":"url","_trustpilot_search_reviews_count":"number","_trustpilot_rating_type":"text","_trustpilot_rating_value":"number","_trustpilot_rating_votes_count":"number","_trustpilot_rating_max":"number"}',
      active: true,
      rate: 4.1,
    },
    {
      id: 119,
      name: 'YELP',
      is_new: false,
      containedPublicWorkflows: [],
      prices: [
        {
          id: 112,
          unit_name: 'request',
          credit_price: 1,
          premium_credit_price: 0.15,
        },
      ],
      url: 'yelp',
      meta_title: 'Yelp Directory Search Automation | Hexomatic',
      meta_description:
        'Our  YELP directory automation can be ideal for marketing and advertising agencies for connecting the clients with high purchase intent customers at scale.',
      short_description: 'Perform YELP directory searches',
      long_description:
        'Tap into search results data from the YELP directory via our managed automation (0.15 premium credit per query for every 10 search results). No proxies or scraping recipes required.',
      icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/yelp.svg',
      category: {
        id: 1,
        name: 'Scraping',
      },
      type: {
        id: 5,
        name: 'Premium automations',
      },
      inputs: 'any',
      outputs:
        '{"_yelp_search_type":"text","_yelp_search_rank_group":"number","_yelp_search_location_longitude":"number","_yelp_search_price_range":"number","_yelp_search_is_guaranteede":"text","_yelp_search_rating_type":"text","_yelp_search_value":"number","_yelp_search_votes_count":"number","_yelp_search_rating_max":"number","_yelp_search_categories":"text","_yelp_search_phone":"number","_yelp_search_photos":"url","_yelp_search_rank_absolute":"number","_yelp_search_tags":"text","_yelp_search_yelp_business_id":"text","_yelp_search_business_url":"url","_yelp_search_alias":"text","_yelp_search_name":"text","_yelp_search_description":"text","_yelp_search_location_address_lines":"text","_yelp_search_location_latitude":"number"}',
      active: true,
      rate: 4.1,
    },
    {
      id: 86,
      name: 'Yahoo search',
      is_new: false,
      containedPublicWorkflows: [],
      prices: [
        {
          id: 63,
          unit_name: 'request',
          credit_price: 1,
          premium_credit_price: 0.04,
        },
      ],
      url: 'yahoo-search',
      meta_title: 'Yahoo Search Automation | Hexomatic',
      meta_description:
        'With our automation, you can perform Yahoo searches and get results instantly. No proxies or scraping recipes are required.',
      short_description: 'Perform Yahoo searches and get SERP results',
      long_description:
        'Perform Yahoo searches and get results via our managed automation (0.04 premium credit per query to get 100 search results). No proxies or scraping recipes required.',
      icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/yahoo.svg',
      category: {
        id: 1,
        name: 'Scraping',
      },
      type: {
        id: 5,
        name: 'Premium automations',
      },
      inputs: 'any',
      outputs:
        '{"_yahoo_keyword":"text","_yahoo_url":"url","_yahoo_domain":"url","_yahoo_title":"text","_yahoo_description":"text","_yahoo_rank_group":"number","_yahoo_rank_absolute":"number","_yahoo_breadcrumb":"url"}',
      active: true,
      rate: 4.1,
    },
    {
      id: 33,
      name: 'Google Translate',
      is_new: false,
      containedPublicWorkflows: [],
      prices: [
        {
          id: 29,
          unit_name: 'character',
          credit_price: 0,
          premium_credit_price: 0.0006,
        },
        {
          id: 30,
          unit_name: 'request',
          credit_price: 1,
          premium_credit_price: 0,
        },
      ],
      url: 'google-translate',
      meta_title: 'Google Translate Automation | Hexomatic',
      meta_description:
        'Our Google translate automation allows you to tap into machine translation at scale and at a fraction of the cost of a dedicated translator.',
      short_description: 'Perform a translation via Google Translate',
      long_description:
        'Perform an automatic machine translation via Google Translate (0.0006 premium credit per character).',
      icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/google-translate.svg',
      category: {
        id: 12,
        name: 'Translation',
      },
      type: {
        id: 5,
        name: 'Premium automations',
      },
      inputs: 'text, any',
      outputs: 'null',
      active: true,
      rate: 4.1,
    },
    {
      id: 45,
      name: 'Email Verification (EasyDMARC)',
      is_new: true,
      containedPublicWorkflows: [],
      prices: [
        {
          id: 107,
          unit_name: 'request',
          credit_price: 1,
          premium_credit_price: 0.17,
        },
      ],
      url: 'email-verification',
      meta_title: 'Email Verification Automation | Hexomatic',
      meta_description:
        'Advanced email validation and verification powered by EasyDMARC can be used to find stale or incorrect email addresses that could harm your email deliverability.',
      short_description: 'Advanced email validation and verification powered by EasyDMARC',
      long_description:
        'Perform advanced multi-layer validation to detect invalid or disposable email addresses which could negatively impact your email deliverability. Features syntax, bounce, MTA, spam trap, disposable, catch all, domain and smtp email checks powered by EasyDMARC.\n<br>EasyDMARC is a B2B SaaS which solves email security and deliverability problems just in a few clicks.',
      icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/email-validation-premium.svg',
      category: {
        id: 14,
        name: 'Validation',
      },
      type: {
        id: 5,
        name: 'Premium automations',
      },
      inputs: 'email address',
      outputs: '{"_email_validation_by_easyDMARC":"text"}',
      active: true,
      rate: 4.1,
    },
    {
      id: 111,
      name: 'Malicious URL checker',
      is_new: true,
      containedPublicWorkflows: [],
      prices: [
        {
          id: 105,
          unit_name: 'request',
          credit_price: 1,
          premium_credit_price: 0.05,
        },
      ],
      url: 'malicious-url-checker',
      meta_title: 'Check URLs for Malware | URL Scanner',
      meta_description:
        'Keep your business safe from scams and fraud. This Malicious URL checker reveals any malicious URLs including phishing and deceptive websites in minutes.',
      short_description: 'Scan any page for malicious or unsafe URLs',
      long_description:
        'Check any page for URLs linking to known unsafe web resources including social engineering, phishing, deceptive, or malware distributing websites.',
      icon_url: 'https://storage.googleapis.com/hexomatic-automation-icons/malicious-url-check.svg',
      category: {
        id: 14,
        name: 'Validation',
      },
      type: {
        id: 5,
        name: 'Premium automations',
      },
      inputs: 'url',
      outputs:
        '{"_malicious_url_malware":"text","_malicious_url_social_engineering":"text","_malicious_url_unwanted_software":"text"}',
      active: true,
      rate: 4.1,
    },
  ]);

  useEffect(() => {
    // Setting document referrer
    const referrer =
      typeof document !== 'undefined' &&
      document &&
      document.referrer &&
      document.referrer.indexOf('https://hexomatic.com') === -1 &&
      document.referrer;
    if (referrer && getRawCookie('document_referrer')) {
      removeDocumentReferrer();
    }
    referrer && setDocumentReferrer(referrer);

    // Setting Impact irclickid in cookies
    const url = window.location.href;
    if (url && url.indexOf('irclickid=') > -1) {
      if (getRawCookie('__irClickId')) {
        removeImpactClickIdCookie();
      }
      const id = url.slice(url.indexOf('irclickid=') + 10);
      const idFin = id.slice(0, id.indexOf('&'));
      setImpactClickIdCookie(idFin);
      removeImpactMediaPartnerIdCookie();
    } else if (url && url.toLocaleLowerCase().indexOf('mediapartnerid=') > -1) {
      if (getRawCookie('__mediaPartnerId')) {
        removeImpactMediaPartnerIdCookie();
      }
      const id = url.slice(url.toLocaleLowerCase().indexOf('mediapartnerid=') + 15);
      const idFin = id.slice(0, id.indexOf('&'));
      setImpactMediaPartnerIdCookie(idFin);
      removeImpactClickIdCookie();
    }
  }, []);

  const handleRedirect = (plan?: string) => {
    window.location.href = plan
      ? `https://dash.hexomatic.com/dashboard/#premium-credits-${plan}`
      : 'https://dash.hexomatic.com/dashboard/#premium-credits';
  };

  return (
    <div className="pc-promo-container">
      <section className="pc-section-dark">
        <div className="text-center section-content" style={{maxWidth: '900px', margin: 'auto'}}>
          <div
            className="text-center"
            style={{color: '#fff', fontSize: '24px', fontWeight: 600, marginBottom: '10px', lineHeight: '41px'}}
          >
            Unlock <span style={{color: '#FFE600'}}>3 Years</span> of Hexomatic Premium
          </div>
          <img src={headerText} alt="Premium credits offer" className="pc-header-img mb-2" />
          <div
            className="text-center"
            style={{color: '#fff', fontSize: '38px', fontWeight: 600, marginBottom: '10px', lineHeight: '41px'}}
          >
            Premium credits <span style={{color: '#FFE600'}}>FLASH PROMO</span>
          </div>
          <div className="text-center" style={{color: '#fff', marginBottom: '20px'}}>
            Access all our premium data sources including ChatGPT, Gemini, Google Gemini,
            Google, Amazon and more with a never-before-seen{' '}
            <span style={{color: '#FFE600'}}>3-Year Hexomatic Premium Plan from just $159!</span>
          </div>
          <div className="text-center" style={{color: '#fff', marginBottom: '20px'}}>
            It’s like getting three years of Premium credits for the price of one.
          </div>

          {/* <Counter date="Feb 01 2024 07:00:00 GMT" light title="DEAL ENDS IN..." /> */}
          <div className="row py-3">
            <div className="col-12 col-md-4">
              <div className="pc_card">
                <div className="title">$159 (3 Year Plan)</div>
                <div className="text">
                  {checkedIconPrice} <strong>100</strong> premium credits / month
                </div>
                <Button className="my-2 w-100" onClick={() => handleRedirect('PC_15900')}>
                  Buy now
                </Button>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="pc_card">
                <div className="title">$249 (3 Year Plan)</div>
                <div className="text">
                  {checkedIconPrice} <strong>200</strong> premium credits / month
                </div>
                <Button className="my-2 w-100" onClick={() => handleRedirect('PC_24900')}>
                  Buy now
                </Button>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="pc_card">
                <div className="title">$499 (3 Year Plan)</div>
                <div className="text">
                  {checkedIconPrice} <strong>500</strong> premium credits / month
                </div>
                <Button className="my-2 w-100" onClick={() => handleRedirect('PC_49900')}>
                  Buy now
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pc-section-light">
        <div className="section-content">
          <h2
            className="mb-5"
            style={{
              fontWeight: 600,
              fontSize: '36px',
              lineHeight: '36px',
              maxWidth: '690px',
              margin: 'auto',
              color: '#050038',
            }}
          >
            1-Click access to multiple services right inside your workflow
          </h2>
          <div style={{maxWidth: '1000px', margin: 'auto'}}>
            <p style={{fontSize: '18px', color: '#3E4345'}}>
              Imagine access to all our premium automations until <strong>2027</strong> without having to worry about
              monthly fees!
            </p>
          </div>
          <div className="mt-5 justify-content-center text-center">
            <img
              src={illustration1}
              className=""
              alt="Premiums promo"
              style={{maxWidth: '900px', margin: 'auto', width: '100%'}}
            />
          </div>
          <Button secondaryDark className="mt-5" onClick={() => handleRedirect()}>
            SECURE THE DEAL{' '}
          </Button>
        </div>
      </section>
      <section className="pc-section-white">
        <div className="section-content">
          <h2
            className="mb-5"
            style={{
              fontWeight: 600,
              fontSize: '36px',
              lineHeight: '36px',
              maxWidth: '690px',
              margin: 'auto',
              color: '#050038',
            }}
          >
            Get access to 35+ Premium automations to 10X your business
          </h2>
          <div className="mt-5 justify-content-center text-center">
            <Row className="automation-card-container-promo">
              {(!showAll ? automations.filter((item, index) => index < 9) : automations).map(item => (
                <Col md={6} lg={4} className="p-2">
                  <Link to={`/automation/${cleanURL(item.url)}`}>
                    <AutomationCards
                      title={item.name}
                      description={item.long_description}
                      icon={item.icon_url}
                      rate={item.rate || 5}
                      active={item.active || false}
                      credit_price={item.credit_price}
                      premium_credit_price={item.premium_credit_price}
                      type={item.type}
                      is_new={item.is_new}
                    />
                  </Link>
                </Col>
              ))}
            </Row>
            <div className="clickable-text-promo mx-auto text-center" onClick={() => setShowAll(!showAll)}>
              {!showAll ? 'Show all' : 'Show less'}
            </div>
          </div>
          <Button secondaryDark className="mt-5" onClick={() => handleRedirect()}>
            SECURE THE DEAL
          </Button>
        </div>
      </section>
      <TrustedSection />

      <BadgeSection title={'Get our award platforms'} titleColor="#050038" />
      <Footer pricingPage={true} />
    </div>
  );
};

export default PremiumsPromo;
